import React, { useState, useEffect } from 'react';
import './DisplayQuestions.css';

function DisplayPinnedChoices({ triggerNextStep, nextDataByChoice }) {
  const [clicked, setClicked] = useState(false);
  const [clickedMessage, setClickedMessage] = useState("");
  const [choices, setChoices] = useState([]);

  // 최초 마운트 시에만 실행되도록 빈 의존성 배열 사용
  useEffect(() => {
    setChoices(nextDataByChoice.map(item => item.question));
  }, []); // 여기를 수정

  const handleClick = (selectedQuestion) => {
    setClicked(true);
    setClickedMessage(selectedQuestion);

    const selectedChoice = nextDataByChoice.find(item => item.question === selectedQuestion);
    
    // onSelect 함수가 있다면 실행
    if (selectedChoice.onSelect) {
      selectedChoice.onSelect();
    }

    setTimeout(() => {
      triggerNextStep(selectedChoice.nextData);
    }, 100);
  };

  return (
    <div className={clicked ? "outer-container clicked" : "outer-container"}>
      {clicked ? (
        <div className="user-message-container">
          <div className="user-message">
            {clickedMessage}
          </div>
        </div>
      ) : (
        <div className="display-button-container">
          {choices.map((choice, index) => (
            <button
              key={`choice-${index}`}
              className="display-styled-button"
              onClick={() => handleClick(choice)}
            >
              {choice}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default DisplayPinnedChoices;