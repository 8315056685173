import React, { useState } from 'react';
import './CustomOptionButton.css';

function CustomOptionButtons({ triggerNextStep, triggerData }) {
  const [clicked, setClicked] = useState(false);
  const { yesOption } = triggerData;

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => {
      triggerNextStep(yesOption);
    }, 500); // 0.5초 후에 triggerNextStep 호출
  };

  return (
    <div className={clicked ? "outer-container clicked" : "outer-container"}>
      {clicked ? (
        <div className="user-message-container">
          <div className="user-message">
            응🙌
          </div>
        </div>
      ) : (
        <div className="button-container">
          <button className="styled-button" onClick={handleClick}>
            응🙌 <span className="arrow">←</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default CustomOptionButtons;