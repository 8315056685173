import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

import newYearImage from '../images/new_year_image.jpg';
import generalImage from '../images/general_image.jpg';
import pairImage from '../images/pair_image.jpg';
import backgroundImage from '../images/background_image.png'

import { useInView } from 'react-intersection-observer'; 
import { handleKakaoLogin } from '../utils/SocialAuth';
import kakaoIcon from '../images/kakao_icon_1.png';  
import { useAuth } from '../contexts/AuthContext';


// 목적지 URL을 저장하기 위한 상수
const REDIRECT_URL_KEY = 'intended_redirect_url';

function FeatureCard({ feature, index }) {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <div 
      ref={ref}
      className={`feature-card ${index % 2 === 0 ? 'left' : 'right'} ${inView ? 'visible' : ''}`}
    >
      <div className="feature-icon">{feature.icon}</div>
      <div className="feature-content">
        <h3 className="feature-title">{feature.title}</h3>
        <p className="feature-description">{feature.description}</p>
      </div>
    </div>
  );
}

function TestimonialCard({ testimonial }) {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <div 
      ref={ref}
      className={`testimonial-card ${inView ? 'visible' : ''}`}
    >
      <div className="testimonial-rating">
        {"★".repeat(testimonial.rating)}
      </div>
      <p className="testimonial-text">{testimonial.text}</p>
      <div className="testimonial-info">
        <span className="testimonial-name">{testimonial.name}</span>
        <span className="testimonial-type">{testimonial.type}</span>
      </div>
    </div>
  );
}

// useTypewriter 훅을 다음과 같이 수정
function useTypewriter(initialText) {
  const [state, setState] = useState({
    displayText: '',
    isComplete: false,
    index: 0
  });

  useEffect(() => {
    // 초기화
    setState({
      displayText: '',
      isComplete: false,
      index: 0
    });

    // 유효성 검사
    if (!initialText) {
      setState(prev => ({ ...prev, isComplete: true }));
      return;
    }

    const text = String(initialText);
    
    const typeNextChar = () => {
      setState(prev => {
        // 마지막 글자 직전인 경우
        if (prev.index === text.length - 1) {
          return {
            displayText: text, // 전체 텍스트를 한번에 표시
            index: text.length,
            isComplete: true
          };
        }

        // 마지막 글자가 아닌 경우 기존 로직 유지
        if (prev.index >= text.length) {
          return { ...prev, isComplete: true };
        }

        return {
          displayText: text.slice(0, prev.index + 1),
          index: prev.index + 1,
          isComplete: false
        };
      });
    };

    const timer = setInterval(() => {
      setState(prev => {
        if (prev.index >= text.length) {
          clearInterval(timer);
          return { ...prev, isComplete: true };
        }
        return prev;
      });
      typeNextChar();
    }, Math.random() * 70 + 35);

    return () => clearInterval(timer);
  }, [initialText]);

  return {
    displayText: state.displayText,
    isComplete: state.isComplete
  };
}

function throttle(func, limit) {
  let inThrottle;
  return function(...args) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      requestAnimationFrame(() => inThrottle = false);
    }
  }
}

function LandingPage() {

  const { isAuthenticated, user, login, logout, loading, checkAuthStatus} = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // for kakao initial login in button section
  const navigate = useNavigate();


  // 텍스트에 정확한 띄어쓰기 포함
  const titleText = {
    desktop: "MIRI | 당신의 운명을 읽다",
    mobile: "운명을\n MIRI 읽다" // 또는 "MIRI | 당신의\n운명을 읽다"
  };
  
  const { displayText, isComplete } = useTypewriter(isMobile ? titleText.mobile : titleText.desktop);


  // useEffect(() => {
  //   // 현재 문서의 모든 쿠키를 출력
  //   console.log('All Cookies:', document.cookie);
    
  //   // 쿠키를 객체 형태로 파싱하여 더 보기 쉽게 출력
  //   const cookies = document.cookie.split(';').reduce((acc, cookie) => {
  //     const [key, value] = cookie.trim().split('=');
  //     acc[key] = value;
  //     return acc;
  //   }, {});
    
  //   console.log('Parsed Cookies:', cookies);
  // }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  //카카오 핸들 로그인
  const handleLogin = async () => {
    console.error('Kakao login trying..');
    setIsLoading(true);
    try {
      await handleKakaoLogin();
    } catch (error) {
      console.error('Kakao login failed:', error);
      // alert('로그인 중 오류가 발생했습니다. 다시 시도해주세요.');
    }
  };

  // 컴포넌트 마운트 시 인증 상태 확인
  useEffect(() => {
    checkAuthStatus(' landing page');
  }, []);

  // 로그아웃 핸들러 추가
  const handleLogout = async () => {
    await logout();
    // 로그아웃 후 필요한 처리 (예: 메시지 표시)
  };

  useEffect(() => {
    if (isAuthenticated) {
      const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY);
      if (redirectUrl) {
        localStorage.removeItem(REDIRECT_URL_KEY);
        navigate(redirectUrl);
      }
    }
  }, [isAuthenticated, navigate]);

  const handleProtectedNavigation = async (targetPath) => {
    setIsLoading(true);
    try {
      await checkAuthStatus('navigation check');
      
      if (!isAuthenticated) {
        // 로그인 후 이동할 URL을 localStorage에 저장
        localStorage.setItem(REDIRECT_URL_KEY, targetPath);
        await handleLogin();
        // 여기서는 navigate를 호출하지 않음 - 카카오 로그인 페이지로 리다이렉트됨
      } else {
        // 이미 로그인된 경우 바로 이동
        navigate(targetPath);
      }
    } catch (error) {
      console.error('Navigation error:', error);
    } 
    // finally {
    //   setIsLoading(false);
    // }
  };


  const services = [
    {
      id: 1,
      title: "2025 신년운세",
      description: "신년 키워드로 올 한 해의 기회와 위기를 미리 준비하세요. 사업운, 금전운, 건강운을 포함한 정확한 신년 운세를 만나보세요.",
      image: newYearImage,
    },
    {
      id: 2,
      title: "운명의 지도",
      description: "사주팔자는 인생이라는 여정의 지도입니다. 수십 년간의 데이터에 기반해 타고난 운명과 숨겨진 잠재력을 섬세하게 분석해 드립니다.",
      image: generalImage,
    },
    {
      id: 3,
      title: "인연의 나침반",
      description: "소중한 인연과의 궁합이 궁금하신가요? 과학적 분석으로 연인, 사업 파트너, 친구와의 관계를 깊이 있게 들여다보고 최적의 관계를 위한 길을 제시해 드립니다.",
      image: pairImage,
    }
  ];

  const features = [
    {
      id: 1,
      title: "3세대에 걸쳐 축적된 명리학의 정수",
      description: "집안 대대로 이어온 역학 지식과 현대 AI 기술의 만남으로, 정확한 운세를 풀어드립니다.",
      icon: "🏛️"
    },
    {
      id: 2,
      title: "과학적 알고리즘",
      description: "사주는 통계학입니다. 수십 년간의 데이터를 AI가 분석하여 정확도 높은 결과를 제공합니다.",
      icon: "🤖"
    },
    {
      id: 3,
      title: "전문적인 사주 분석",
      description: "태어난 월과 일간의 관계성, 팔자에 미치는 영향 등 심도 있는 분석으로 겉핥기식 풀이는 No!",
      icon: "📚"
    },
    {
      id: 4,
      title: "방대한 데이터베이스",
      description: "명리학 고서부터 최신 논문까지, 수십 권의 전문 서적 데이터베이스가 AI에 탑재되어 있습니다.",
      icon: "💡"
    },
    {
      id: 5,
      title: "맞춤형 상담",
      description: "단순한 운세가 아닌, 상황과 고민에 특화된 1:1 맞춤형 상담을 제공합니다.",
      icon: "🤝"
    },
    {
      id: 6,
      title: "쉽고 정확한 풀이",
      description: "어려운 사주 용어는 NO! 이해하기 쉽게 풀어서 설명해드립니다.",
      icon: "✨"
    }
  ];

  
  const testimonials = [
    {
      id: 1,
      name: "김*정님",
      age: "28세",
      rating: 5,
      text: "정확도가 놀라웠어요. 특히 직장운에 대한 조언이 큰 도움이 되었습니다.",
      type: "신년운세"
    },
    {
      id: 2,
      name: "이*현님",
      age: "35세",
      rating: 5,
      text: "AI가 분석해준다고 해서 반신반의했는데, 정말 놀라울 정도로 정확했습니다.",
      type: "궁합"
    },
    {
      id: 3,
      name: "박*린님",
      age: "42세",
      rating: 5,
      text: "전통 사주와는 다른 새로운 인사이트를 얻을 수 있어서 좋았어요.",
      type: "총운"
    },
    {
      id: 4,
      name: "최*우님",
      age: "31세",
      rating: 5,
      text: "사업 시작 전에 봤는데, 조언해준 대로 진행하니 정말 도움이 많이 됐습니다.",
      type: "신년운세"
    },
    {
      id: 5,
      name: "정*아님",
      age: "27세",
      rating: 5,
      text: "연애운 분석이 너무 정확해서 놀랐어요. 앞으로의 인연도 기대됩니다!",
      type: "총운"
    },
    {
      id: 6,
      name: "강*준님",
      age: "39세",
      rating: 5,
      text: "비즈니스 파트너와의 궁합을 봤는데, 예측한 대로 진행되어서 만족스럽습니다.",
      type: "궁합"
    }
  ];

  const processSteps = [
    {
      id: 1,
      icon: "📝",
      title: "기본 정보 입력",
      description: "생년월일시를 입력하여 사주팔자를 구합니다."
    },
    {
      id: 2,
      icon: "🔄",
      title: "AI 데이터 분석",
      description: "30년간의 데이터베이스와 AI가 당신의 운세를 분석합니다."
    },
    {
      id: 3,
      icon: "🎯",
      title: "맞춤형 결과 제공",
      description: "과학적 분석을 통해 정확하고 실용적인 운세를 제공합니다."
    },
    {
      id: 4,
      icon: "💬",
      title: "1:1 상담",
      description: "추가 질문과 고민에 대해 AI가 상세히 답변해드립니다."
    }
  ];

  const fortuneCategories = [
    {
      id: 1,
      icon: "💸",
      title: "재물운",
      description: "내년에 나는 돈을 많이 벌 수 있을까?"
    },
    {
      id: 2,
      icon: "❤️",
      title: "애정운",
      description: "새로운 인연이 찾아올까? 지금 만나는 사람과는 어떨까?"
    },
    {
      id: 3,
      icon: "💼",
      title: "직장/명예운",
      description: "취업이나 이직, 직장에서의 미래가 궁금하다면"
    },
    {
      id: 4,
      icon: "👨‍🎓",
      title: "학업/계약운",
      description: "공부나 자격증, 문서 계약운은 어떨까"
    },
    {
      id: 5,
      icon: "🔮",
      title: "2025년 나에게 다가올 행운",
      description: "내가 생각지 못한 행운이 있을까?"
    }
  ];

  useEffect(() => {
    // 모바일에서는 더 적은 횟수로 업데이트
    const scrollMultiplier = isMobile ? 0.05 : 0.1;
    
    const handleScroll = throttle(() => {
      const scrollY = window.scrollY;
      const viewportHeight = window.innerHeight;
      
      if (scrollY <= viewportHeight) {
        // requestAnimationFrame을 사용하여 부드러운 애니메이션
        requestAnimationFrame(() => {
          setScrollPosition(scrollY);
        });
      }
    }, 16); // 약 60fps

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  const calculateTransforms = (scrollPos) => {
    const moveX = scrollPos * 0.1; // 좌우 이동
    const scale = 1 + (scrollPos * 0.0005); // 확대/축소
    return {
      transform: `
        translateX(${moveX}px) 
        scale(${scale})
      `
    };
  };

  return (
    <div className="home-page">
      {/* Mountain parallax layers */}
      <div className="mountain-scene">
        <div 
          className={`mountain-layer ${backgroundImage ? 'with-image' : ''}`}
          style={{ 
            transform: isMobile ? 
              `translateX(${scrollPosition * 0.3}px)` : 
              `translateX(${scrollPosition * 0.2}px)`,
            '--bg-image': `url(${backgroundImage})`
          }}
        />
        <div className="trees-layer" />
      </div>
  
      {/* Content */}
      <div className="home-content">

        <div className="hero-section"> {/* 새로운 wrapper 추가 */}
          <div 
            className="title-section"
            >
            <h1 className={`main-title ${isComplete ? 'floating' : ''}`}>
              {displayText}
              {!isComplete && <span className="cursor"></span>}
            </h1>
            <p className="sub-title">AI와 명리학의 만남, 새로운 차원의 운세 상담</p>
            
            <div className="store-buttons">
              {isAuthenticated ? (
                <button 
                  onClick={handleLogout}
                  className="store-button logout-button"
                >
                  로그아웃
                </button>
              ) : (
                <button 
                onClick={handleLogin} 
                className={`store-button kakao-login ${isLoading ? 'loading' : ''}`}
                aria-label="카카오 로그인"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className="loading-spinner"></span>
                ) : (
                  <>
                    <img src={kakaoIcon} alt="카카오 아이콘" className="kakao-icon" />
                    카카오 로그인
                  </>
                )}
              </button>
              )}
            </div>
          </div>
        </div>
  
        <div className="services-section"> {/* 새로운 wrapper 추가 */}
          <div className="game-cards">
            {services.map((service) => (
              <div key={service.id} className="game-card">
                <div className="game-card-image">
                  <img src={service.image} alt={service.title} />
                </div>
                <div className="game-card-content">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>



      <div className="features-section">
        <div className="features-content">
          <h2 className="section-title">왜 MIRI여야 할까요?</h2>
          <p className="section-subtitle">운명을 과학적으로 분석하는 새로운 방법</p>
          
          <div className="features-grid">
            {features.map((feature, index) => (
              <FeatureCard 
                key={feature.id} 
                feature={feature} 
                index={index}
              />
            ))}
          </div>
        </div>
      </div>




      {/* // Testimonials Section in LandingPage component */}
      <div className="testimonials-section">
        <div className="testimonials-content">
          <h2 className="section-title">사용자 후기</h2>
          <p className="section-subtitle">MIRI와 함께한 분들의 이야기</p>
          
          <div className="testimonials-carousel">
            {testimonials.map((testimonial) => (
              <TestimonialCard 
                key={testimonial.id} 
                testimonial={testimonial}
              />
            ))}
          </div>
        </div>
      </div>

      {/* New Process Section */}
      <div className="process-section">
        <div className="process-content">
          <h2 className="section-title">상담 진행 과정</h2>
          <p className="section-subtitle">쉽고 정확한 4단계 프로세스</p>
          
          <div className="process-steps">
            {processSteps.map((step) => (
              <div key={step.id} className="process-step">
                <div className="step-icon">{step.icon}</div>
                <h3 className="step-title">{step.title}</h3>
                <p className="step-description">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/*신년운세 CTA  */}
      <div className="cta-section">
        <div className="cta-content">
          <h2 className="section-title">2025년, 내 삶은 어떻게 펼쳐질까?</h2>
          <p className="section-subtitle">
            수십 년간 축적된 사주 데이터를 바탕으로 정확한 운세를 풀어드립니다.
          </p>
          
          <div className="fortune-categories">
            {fortuneCategories.map((fortune, index) => (
              <FeatureCard
                key={fortune.id}
                feature={{
                  icon: fortune.icon,
                  title: fortune.title,
                  description: fortune.description
                }}
                index={index}
              />
            ))}
          </div>

          <button 
            className={`cta-button ${isLoading ? 'loading' : ''}`}
            onClick={() => handleProtectedNavigation('/new-year-prospects')}
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="loading-spinner"></span>
            ) : (
              '2025년 신년운세 보러가기'
            )}
          </button>
        </div>
      </div>




      </div>
    </div>
  );
}

export default LandingPage;