import React, { useState } from "react";
import { NavLink,useNavigate } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import "./NavBar.css";
import logoImage from '../seowha_logo.png';
import { useAuth } from '../contexts/AuthContext';
import {handleAuthProtection} from '../utils/SocialAuth'

const NavBar = () => {
  const { isAuthenticated, logout, loading } = useAuth();  // loading 상태 추가
  const [showMenu, setShowMenu] = useState(false);
  const [error, setError] = useState(null);  // 에러 상태 추가
  const navigate = useNavigate();  // useNavigate 훅 추가


const handleHomeClick = (e) => {
  console.log('handleHomeClick 실행');
  console.log('현재 경로:', window.location.pathname);
  console.log('현재 스크롤 위치:', document.documentElement.scrollTop);

  if (window.location.pathname === '/home') {
    e.preventDefault();
  }

  document.documentElement.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
  
  closeMenuOnMobile();
};

  const handleLogout = async () => {
    try {
      await logout();
      setShowMenu(false);  // 로그아웃 후 메뉴 닫기
    } catch (error) {
      setError('로그아웃 중 오류가 발생했습니다.');
      setTimeout(() => setError(null), 3000);
    }
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    const toggle = document.querySelector('.nav__toggle');
    toggle.classList.toggle('rotate');
  };
   
  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  };


  const handleProtectedRoute = (e, path) => {
    e.preventDefault();
    if (handleAuthProtection(isAuthenticated, navigate, path)) {
      navigate(path);
    }
    closeMenuOnMobile();
  };

  return (
    <header className="header">
      <nav className="nav">
        <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
          <IoMenu />
        </div>

        <NavLink to="/home" className="nav__logo" onClick={handleHomeClick}>
          <img src={logoImage} alt="서화사주 로고" className="nav__logo-image" />
        </NavLink>

        <div className={`nav__menu ${showMenu ? 'show-menu' : ''}`} id="nav-menu">
          <ul className="nav__list">
            {/* <li className="nav__item">
              <NavLink 
                  to="/home" 
                  className="nav__link" 
                  onClick={handleHomeClick}
                >
                  홈
              </NavLink>
            </li> */}
            <li className="nav__item">
              <a 
                href="/home" 
                className="nav__link" 
                onClick={(e) => handleProtectedRoute(e, '/home')}
              >
                사주 시작하기
              </a>
            </li>
            <li className="nav__item">
              <a 
                href="/home" 
                className="nav__link" 
                onClick={(e) => handleProtectedRoute(e, '/home')}
              >
                일주유형
              </a>
            </li>
            <li className="nav__item">
              <a 
                href="/home" 
                className="nav__link" 
                onClick={(e) => handleProtectedRoute(e, '/home')}
              >
                나의격
              </a>
            </li>
            <li className="nav__item">
              <a 
                href="/new-year-prospects" 
                className="nav__link" 
                onClick={(e) => handleProtectedRoute(e, '/new-year-prospects')}
              >
                신년운세
              </a>
            </li>
            <li className="nav__item">
              <a 
                href="/how-special-you-are" 
                className="nav__link" 
                onClick={(e) => handleProtectedRoute(e, '/how-special-you-are')}
              >
                운명의지도
              </a>
            </li>
            <li className="nav__item">
              {isAuthenticated ? (
                <span className="nav__link" onClick={handleLogout} style={{ cursor: 'pointer' }}>
                로그아웃
              </span>
              ) : (
                <a 
                  href="/home"
                  className="nav__link"
                  onClick={(e) => handleProtectedRoute(e, '/home')}
                >
                  로그인
                </a>
              )}
            </li>
          </ul>
          <div className="nav__close" id="nav-close" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>
      </nav>
      {showMenu && <div className="nav__overlay" onClick={toggleMenu}></div>}
    </header>
  );
};

export default NavBar;