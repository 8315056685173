import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CustomHeader = ({ image }) => {
  const headerStyle = {
    backgroundColor: 'white',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #e0e0e0',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  };

  const imageStyle = {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
  };

  const titleStyle = {
    margin: 0,
    fontSize: '1.2rem',
    fontWeight: 'bold',
  };

  return (
    <header style={headerStyle}>
      {image && <img src={image} alt="Logo" style={imageStyle} />}
    </header>
  );
};

CustomHeader.propTypes = {
  image: PropTypes.string
};

export default CustomHeader;