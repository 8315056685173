import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

// TODO
// saju-page지와 동일하게 기본 내브바 밑으로 밀리지 않도록 구현 position fix

// 카카오 버튼 넣고
// saju-page에서는 로그인이 안됐을 경우 팝업띄어주고 확인버튼은 홈으로 가도록

function LoginPage() {
  const navigate = useNavigate();

  const handleKakaoLogin = () => {
    // Kakao SDK를 사용한 로그인 로직
    window.Kakao.Auth.authorize({
      redirectUri: 'https://www.tagmeet.io/accounts/kakao/callback/',
    });
  };

  return (
    <div className="page-container">
      <h1 className="title">로그인</h1>
      <button className="kakao-button" onClick={handleKakaoLogin}>
        카카오로 로그인
      </button>
    </div>
  );
}

export default LoginPage;