import React, { useEffect } from 'react';
import './FortuneList.css';

const NewYearFortuneList = ({ triggerNextStep, triggerData }) => {
  const fortuneItems = [
    { id: 1, title: '2025 운세 총평' },
    { id: 2, title: '재물운' },
    { id: 3, title: '애정운' },
    { id: 4, title: '직장 명예운' },
    { id: 5, title: '학업 계약운' },
    { id: 6, title: '2025년 나에게 다가올 행운' },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      triggerNextStep(triggerData);
    }, 100); // 1초 후에 다음 단계로 넘어감

    return () => clearTimeout(timer);
  }, []); // Add dependencies


  return (
    <div className="fortune-list-container">
      <h2 className="fortune-list-title">25년 운세 풀이 순서</h2>
      <ul className="fortune-list">
        {fortuneItems.map((item) => (
          <li key={item.id} className="fortune-list-item">
            <span className="fortune-item-number">{item.id}</span>
            <span className="fortune-item-title">{item.title}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewYearFortuneList;