import React, { useState, useEffect, useRef }  from 'react';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Navigate,
  useNavigate 
} from 'react-router-dom';
import { ThreadProvider } from './ThreadContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import LandingPage from './pages/LandingPage';
import HowSpecialPage from './pages/HowSpecialPage';
import NewYearPage from './pages/NewYearPage';
import LoginPage from './pages/LoginPage';
import NavBar from './components/NavBar';
import {handleAuthProtection} from './utils/SocialAuth'
import { useLocation } from 'react-router-dom';
import './App.css';


function ScrollToTop() {
  const { pathname } = useLocation();
  const prevPathRef = useRef(pathname);

  useEffect(() => {
    console.log('ScrollToTop Effect 실행');
    console.log('이전 경로:', prevPathRef.current);
    console.log('현재 경로:', pathname);

    if (prevPathRef.current !== pathname || pathname === '/home') {
      console.log('스크롤 실행');
      // window와 document.documentElement 모두 시도
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      document.documentElement.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      // body도 시도
      document.body.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

    prevPathRef.current = pathname;
    console.log('현재 스크롤 위치:', window.pageYOffset);
  }, [pathname]);

  return null;
}

function App() {


  return (
    <div className="App">
      <AuthProvider>
        <ThreadProvider>
          <Router>
            <ScrollToTop />
            <NavBar />
            <div className="content-wrapper">
              <Routes>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<LandingPage />} />
                <Route 
                  path="/how-special-you-are" 
                  element={
                    <ProtectedRoute>
                      <HowSpecialPage />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/new-year-prospects" 
                  element={
                    <ProtectedRoute>
                      <NewYearPage />
                    </ProtectedRoute>
                  } 
                />
              </Routes>
            </div>
          </Router>
        </ThreadProvider>
      </AuthProvider>
    </div>
  );
}

// ProtectedRoute 컴포넌트 수정
function ProtectedRoute({ children }) {
  const { isAuthenticated, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      handleAuthProtection(isAuthenticated, navigate);
    }
  }, [isAuthenticated, loading, navigate]);

  if (loading) return <div>Loading...</div>;
  return isAuthenticated ? children : null;
}

export default App;
