import React, { useState, useEffect, useMemo } from 'react';
import './DisplayQuestions.css';

function DisplayQuestions({ triggerNextStep, questions, removeQuestion, questionKey, category }) {
  const [clicked, setClicked] = useState(false);
  const [clickedMessage, setClickedMessage] = useState("");
  const [allQuestions, setAllQuestions] = useState([]);

  const defaultQuestions = useMemo(() => ["자유 질문을 할래", "다음으로 넘어갈게🙌"], []);
  // const defaultQuestions = useMemo(() => ["다음으로 넘어갈게🙌"], []);

  useEffect(() => {
    setAllQuestions([...questions, ...defaultQuestions]);
    setClicked(false);
    setClickedMessage("");
  }, []);

  const handleClick = (message) => {
    setClicked(true);
    setClickedMessage(message);

    // 상태 업데이트를 지연시켜 user-message가 먼저 렌더링되도록 함
    setTimeout(() => {
      let nextData = {}

      if (message === '다음으로 넘어갈게🙌') {
        nextData = {
          trigger: 'user-input-processor',
          value: '다음'
        };
      } else if (message === '자유 질문을 할래') {
        nextData = {
          trigger: 'make-system-message',
          value: {
            message: ["어떤게 궁금해??"],
            next_trigger: 'user-input',
          }
        };
      } else {
        nextData = {
          trigger: 'user-input-processor',
          value: message
        }
        removeQuestion(message);
      }

      triggerNextStep(nextData);
    }, 100);
  };

  return (
    <div className={clicked ? "outer-container clicked" : "outer-container"}>
      {clicked ? (
        <div className="user-message-container">
          <div className="user-message">
            {clickedMessage}
          </div>
        </div>
      ) : (
        <div className="display-button-container">
          {allQuestions.map((question, index) => (
            <button
              key={`${questionKey}-${index}`}
              className="display-styled-button"
              onClick={() => handleClick(question)}
            >
              {question}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default DisplayQuestions;