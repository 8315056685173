import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // 초기 로드시 인증 상태 확인
    checkAuthStatus( 'first load');
  }, []);

  const checkAuthStatus = async (page) => {
    try {
      // credentials: 'include'로 쿠키를 포함하여 요청
      const response = await fetch('https://www.tagmeet.io/accounts/token-check/', {
        credentials: 'include', // 쿠키 포함
        headers: {
          'Content-Type': 'application/json',
        }
      });
      
      console.log("Checking Auth Status in ", page)
      if (response.ok) {
        const data = await response.json();
        setUser(data.user);
        setIsAuthenticated(true);
      } else {
        logout();
      }
    } catch (error) {
      console.error('Auth check error:', error);
      // logout();
    } finally {
      setLoading(false);
    }
  };

  const login = (userData) => {
    // 쿠키는 서버에서 설정하므로 여기서는 사용자 정보만 설정
    setUser(userData);
    setIsAuthenticated(true);
  };

  const logout = async () => {
    try {
      const response = await fetch('https://www.tagmeet.io/accounts/custom/logout/', {
        method: 'POST',
        credentials: 'include',
      });
      
      if (response.ok) {
        // 로컬 상태 초기화
        setUser(null);
        setIsAuthenticated(false);
        
        // 브라우저의 쿠키도 직접 삭제
        document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.tagmeet.io';
        document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=www.tagmeet.io';
        
        // 선택적: 페이지 새로고침
        // window.location.reload();
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      user, 
      login, 
      logout,
      loading,
      checkAuthStatus // 상태 재확인이 필요할 때 사용
    }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};